import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import Footer from '../Footer';

const Nebulox: React.FC = () => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});
  const iconRefs = useRef<{ [key: string]: HTMLSpanElement | null }>({});
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const toggleSection = (section: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); 
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    videoRefs.current.forEach(video => {
      if (video) {
        if (isSmallScreen) {
          video.pause();
          video.removeAttribute('autoplay');
          video.controls = true; // Show controls on small screens
        } else {
          video.setAttribute('autoplay', '');
          video.controls = false; // Hide controls on medium/large screens
          video.play().catch(e => console.error('Error attempting to play video:', e));
        }
      }
    });
  }, [isSmallScreen]);

  useEffect(() => {
    Object.entries(iconRefs.current).forEach(([title, ref]) => {
      if (ref) {
        gsap.to(ref, {
          rotation: openSections[title] ? 180 : 0,
          duration: 0.3,
          ease: 'power2.inOut',
        });
      }
    });
  }, [openSections]);

  return (
    <div className="bg-paper">
      {/* Video section */}
      <div className="w-full h-[full] pt-20">
        <video 
          ref={el => videoRefs.current[0] = el}
          className="w-full h-full md:px-12 object-cover" 
          loop 
          muted 
          playsInline
        >
          <source src="/assets/Intro.mp4" type="video/mp4" />
        </video>
      </div>
      <h1 className="text-subheading py-12 md:px-12 sm:m-6 md:m-0">NEBULOX HEADPHONES</h1>

      {/* Content section */}
      <div className="flex flex-col md:flex-row">
        {/* Left column */}
        <div className="w-full md:w-1/3 px-4 md:px-12">
          <div className="mb-4">
            <h2
              className="text-xl text-paragraph pb-4 pt-10 cursor-pointer flex justify-between items-center"
              onClick={() => toggleSection('3D Headphone Design')}
            >
              3D Headphone Design
              <span ref={(el) => (iconRefs.current['3D Headphone Design'] = el)}>
                {openSections['3D Headphone Design'] ? '−' : '+'}
              </span>
            </h2>
            <div className="overflow-hidden">
              <p
                className={`text-small leading-relaxed transition-opacity duration-300 ${
                  openSections['3D Headphone Design'] ? 'opacity-100' : 'opacity-0'
                }`}
              >
                In this project, a pair of headphones was designed using Blender and rendered
                with Octane to achieve photorealistic quality. Every detail of the product, from the texture
                of the materials to the interplay of light and shadow, was carefully crafted to create a
                striking, true-to-life visual. The result was a high-fidelity 3D model that fully captured the
                form and function of the headphones.
              </p>
            </div>
          </div>

          <div className="mb-4">
            <h2
              className="text-xl text-paragraph pb-4 pt-10 cursor-pointer flex justify-between items-center"
              onClick={() => toggleSection('Interactive Web Experience')}
            >
              Interactive Web Experience
              <span ref={(el) => (iconRefs.current['Interactive Web Experience'] = el)}>
                {openSections['Interactive Web Experience'] ? '−' : '+'}
              </span>
            </h2>
            <div className="overflow-hidden">
              <p
                className={`text-small leading-relaxed transition-opacity duration-300 ${
                  openSections['Interactive Web Experience'] ? 'opacity-100' : 'opacity-0'
                }`}
              >
                Once the 3D model was completed, it was integrated into a custom-built website using React.js,
                Three.js, and GSAP. The site was designed to feature interactive 3D elements, allowing users
                to engage directly with the product. The implementation demonstrated a strong blend of web
                development and 3D visualization techniques, showcasing the product in an immersive and
                interactive environment.
              </p>
            </div>
          </div>

          <div className="mb-4">
            <h2
              className="text-xl text-paragraph pb-4 pt-10 cursor-pointer flex justify-between items-center"
              onClick={() => toggleSection('From Concept to Execution')}
            >
              From Concept to Execution
              <span ref={(el) => (iconRefs.current['From Concept to Execution'] = el)}>
                {openSections['From Concept to Execution'] ? '−' : '+'}
              </span>
            </h2>
            <div className="overflow-hidden">
              <p
                className={`text-small leading-relaxed transition-opacity duration-300 ${
                  openSections['From Concept to Execution'] ? 'opacity-100' : 'opacity-0'
                }`}
              >
                This project exemplifies a full-cycle product visualization, starting from initial 3D design
                to the development of an interactive web platform. Through a thoughtful combination of
                detailed modeling and advanced web technologies, the project highlights the ability to take a
                concept from initial design through to a polished, dynamic digital display, demonstrating
                expertise in both 3D rendering and web integration.
              </p>
            </div>
          </div>

          <div className="mb-4 pt-10">
            <img src="/assets/Closed.jpg" alt="Headphones Image" className="w-full aspect-square object-cover" />
          </div>
          <div className="mb-4 pt-10">
            <video 
              ref={el => videoRefs.current[1] = el}
              className="w-full h-full object-cover" 
              loop 
              muted 
              playsInline
            >
              <source src="/assets/realtimeChange.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        {/* Right column */}
        <div className="w-full md:w-1/2 p-4 md:ml-[300px]">
          <a href="https://tuneseeker.online/" target="_blank" rel="noopener noreferrer" className="text-subtitle underline pb-4 block">
            Explore the Full Project
          </a>
          <div className="mb-4">
            <img src="/assets/Open.jpg" alt="Square Image" className="w-full aspect-square object-cover pr-0 md:pr-8" />
          </div>
          {/* <div className="mb-4">
            <p>Your text explanation here...</p>
          </div> */}
          <div className="mb-4">
            <h2
              className="text-xl text-paragraph pb-4 pt-10 cursor-pointer flex justify-between items-center"
              onClick={() => toggleSection('Technical Specifications')}
            >
              Real-Time Interaction with Three.js
              <span ref={(el) => (iconRefs.current['Technical Specifications'] = el)}>
                {openSections['Technical Specifications'] ? '−' : '+'}
              </span>
            </h2>
            <div className="overflow-hidden">
              <p
                className={`text-small leading-relaxed 
                  transition-opacity duration-300 max-w-full md:max-w-md ${
                  openSections['Technical Specifications'] ? 'opacity-100' : 'opacity-0'
                }`}
              >
                A key feature of the website is a section where users can interact with 
                the 3D model of the headphones, designed in Blender and imported into 
                the site using Three.js. By leveraging Three.js’s lighting and material 
                features, the model was displayed with realistic shading and reflections, 
                creating an immersive product showcase. The site allowed users to change 
                the color of the headphones in real-time, offering a dynamic experience 
                that enhances user engagement and personalization.
              </p>
            </div>
          </div>

          <div className="mb-4">
            <h2
              className="text-xl text-paragraph pb-4 pt-10 cursor-pointer flex justify-between items-center"
              onClick={() => toggleSection('User Experience')}
            >
              State Management and E-Commerce Integration
              <span ref={(el) => (iconRefs.current['User Experience'] = el)}>
                {openSections['User Experience'] ? '−' : '+'}
              </span>
            </h2>
            <div className="overflow-hidden">
              <p
                className={`text-small leading-relaxed 
                  transition-opacity duration-300 max-w-full md:max-w-md ${
                  openSections['User Experience'] ? 'opacity-100' : 'opacity-0'
                }`}
              >
                To further improve the user experience, Redux was implemented for state 
                management, ensuring that color customization and the purchasing process 
                functioned smoothly. Once users selected their preferred color, they could 
                easily add the headphones to the cart, with Redux managing the state to 
                track all product details. This feature integrated real-time interaction 
                with an e-commerce solution, providing a seamless and interactive shopping 
                experience.
              </p>
            </div>
          </div>

          <div className="mb-4">
            <h2
              className="text-xl text-paragraph pb-4 pt-10 cursor-pointer flex justify-between items-center"
              onClick={() => toggleSection('Other')}
            >
              Merging Web Development and 3D for Interactive Experiences
              <span ref={(el) => (iconRefs.current['Other'] = el)}>
                {openSections['Other'] ? '−' : '+'}
              </span>
            </h2>
            <div className="overflow-hidden">
              <p
                className={`text-small leading-relaxed 
                  transition-opacity duration-300 max-w-full md:max-w-md ${
                  openSections['Other'] ? 'opacity-100' : 'opacity-0'
                }`}
              >
                This project emerged from a desire to blend web development with 
                3D design to create more engaging product interactions. By 
                combining tools like React.js and Three.js with 3D models, 
                the aim was to make products visually appealing and 
                interactive. Users could explore and customize the product 
                in real-time, offering a more personalized and immersive 
                experience. This approach redefined traditional e-commerce 
                by adding a dynamic, playful layer to user engagement.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[full] pt-20">
        <video 
          ref={el => videoRefs.current[2] = el}
          className="w-full h-full md:px-12 sm:px-0 object-cover" 
          loop 
          muted 
          playsInline
        >
          <source src="/assets/cushion.mp4" type="video/mp4" />
        </video>
      </div>
      <Footer githubLink="https://github.com/thekain9/noise" />
    </div>
  );
};

export default Nebulox;



