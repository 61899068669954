import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import Footer from '../Footer';

const DropdownSection: React.FC<{ title: string; description: string }> = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef<HTMLSpanElement>(null);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (iconRef.current) {
      gsap.to(iconRef.current, {
        rotation: isOpen ? 180 : 0,
        duration: 0.3,
        ease: "power2.inOut"
      });
    }
  }, [isOpen]);

  return (
    <div>
      <h2 
        className="text-xl text-paragraph pb-4 pt-10 cursor-pointer 
        flex justify-between items-center mr-10"
        onClick={toggleSection}
      >
        {title}
        <span ref={iconRef}>{isOpen ? '−' : '+'}</span>
      </h2>
      <div className="md:h-40 sm:60 overflow-hidden"> {/* Fixed height container */}
        <div className={`text-small leading-relaxed
                  transition-opacity duration-300 max-w-md
                  ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const WebDesign: React.FC = () => {
  return (
    <div className="bg-paper">
      <h1 className="text-subheading mb-6">WEB DESIGN</h1>
      <div className="flex flex-col md:flex-row md:mb-12 sm:mb-0 px-2">
        <div className="w-full md:w-1/2 flex flex-col justify-center">
          <DropdownSection 
            title="SEO and User Experience Enhancements" 
            description="For Primitivo Coffee, a local coffee brand, 
            enhancements focused on improving the site’s SEO performance 
            and user experience. By optimizing meta tags, improving 
            page load speed, and restructuring content with more 
            relevant keywords, the site's visibility on search
            engines increased. Additionally, small design adjustments 
            were made to improve mobile responsiveness and overall user 
            navigation, ensuring a smoother experience across all devices. 
            These refinements helped increase organic traffic and 
            engage potential customers more effectively." 
          />
        </div>
        <div className="w-full md:w-1/2 mt-8">
          <img src="/assets/Café Colombiano _ Café Primitivo _ Colombia — Mozilla Firefox 15_04_2024 13_55_26.png" alt="Image 1" className="w-full h-full object-cover" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row-reverse mb-12">
        <div className="w-full md:w-1/2 p-6 flex flex-col justify-center">
          <DropdownSection 
            title="Interactive Features and Performance Tuning" 
            description="For Bow Wow International’s Team Ikuzawa site, 
            the focus was on enhancing the interactivity and 
            performance of the existing website. Subtle yet 
            impactful updates included optimizing image files 
            for faster load times and implementing lazy loading 
            for media-heavy pages. Additionally, interactive 
            features such as hover animations and smooth 
            scrolling were integrated to create a more 
            dynamic user experience. These improvements 
            not only enhanced site performance but also 
            provided a more engaging interface for visitors, 
            aligning the site with modern web standards." 
          />
        </div>
        <div className="w-full md:w-1/2">
          <img src="/assets/Screenshot 2024-04-08 at 11.20.03.png" alt="Image 2" className="w-full h-full object-cover" />
        </div>
      </div>

      {/* <div className="flex flex-col md:flex-row mb-12">
        <div className="w-full md:w-1/2 p-6 flex flex-col justify-center">
          <DropdownSection 
            title="Section 3" 
            description="Description for section 3..." 
          />
        </div>
        <div className="w-full md:w-1/2">
          <img src="/path-to-image-3.jpg" alt="Image 3" className="w-full h-full object-cover" />
        </div>
      </div> */}
      <div className='md:mt-60 sm:mt-10'>
        <Footer />
      </div>
    </div>
  );
};

export default WebDesign;

